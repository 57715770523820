<template>
  <div>
    <button @click="actions.showTestModal()">테스트</button>
    <teleport :disabled="true">
      <div v-if="state.showModal" class="side-modal-wrapper">
        <div ref="sideModal" class="side-modal">
          <div class="menu-btn">
            <button class="" @click="actions.closeTestModal()">
              <img
                src="/assets/images/svg/ic_close_line_gray_30.svg"
                width="14"
              />
            </button>
          </div>
          <div class="side-modal-header">
            <h1>header</h1>
          </div>

          <div class="side-modal-body"></div>

          <div class="side-modal-footer">
            <h1>Footer</h1>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { reactive, ref } from "vue";

export default {
  name: "LabsSideModal",
  setup() {
    const sideModal = ref();

    const state = reactive({
      showModal: false,
    });

    const actions = {
      showTestModal: () => {
        state.showModal = true;

        setTimeout(() => {
          sideModal.value.style.right = "0%";
        }, 50);
      },
      closeTestModal: () => {
        sideModal.value.style.right = "-25%";
        setTimeout(() => {
          state.showModal = false;
        }, 200);
      },
    };

    return { state, actions, sideModal };
  },
};
</script>

<style scoped>
.menu-btn {
  position: absolute;
  right: 0;
  margin-right: 24px;
}

.side-modal-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: absolute;
  display: flex;
  flex-flow: column;
  top: 0;
  left: 0;
  width: 100%;
}

.side-modal {
  width: 25%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -25%;
  background-color: #ffffff;
  transition: right 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.side-modal-header {
  width: 100%;
  height: 85px;
  background-color: red;
}

.side-modal-body {
  flex: 1 1 auto;
  overflow: scroll;
}

.side-modal-footer {
  height: 85px;
  background-color: red;
}
</style>
